/* global.css */

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.UploadComponent {
  margin: 70px auto !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
}

div:where(.swal2-icon).swal2-warning {
  border-color: #dd3333 !important;
  color: #dd3333 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}

.custom-image {
  border-radius: 5px;
}
/* .custom-toast-height {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* Example of custom pagination styles */
/* Custom styles for Swiper navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #fff !important; /* Change arrow color */
  background: rgba(
    0,
    0,
    0,
    0.5
  ) !important; /* Add a semi-transparent background */
  border-radius: 50% !important; /* Make the background circular */
  width: 40px !important; /* Adjust the size of the arrow container */
  height: 40px !important; /* Adjust the size of the arrow container */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 15px !important; /* Increase arrow size */
}

/* Add hover effect */
.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: #182c51 !important;
}

.MuiAutocomplete-inputRoot {
  overflow: scroll !important;
}
