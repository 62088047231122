/* globalScrollbar.css */

/* Customize the width and appearance of the scrollbar */
::-webkit-scrollbar {
  color: #888 #f1f1f1;
  width: 4px; /* Width of the vertical scrollbar */
  height: 6px; /* Height of the horizontal scrollbar */
}

/* Customize the track (background) of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 30px; /* Rounded corners */
}

/* Customize the thumb (the moving part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 30px; /* Rounded corners */
}

/* Customize the thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color when hovered */
}
