body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s;
}
.swiper-slide:hover img {
  transform: scale(1.1);
}

.swiper {
  width: 100%;
  height: 200px;
  margin: 20px auto;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.582);
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;

  transition: background-color 0.3s ease;
}
.image-overlay:hover {
  background-color: rgba(0, 0, 0, 0.24);
}
.image-overlay-inside {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-text {
  color: white;
  font-size: 24px;
  font-weight: 500;
}

.swiper-pagination-fraction {
  display: none;
}
.swal2-container {
  z-index: 9999 !important;
}
.MuiFab-root {
  background-color: #182c61 !important;
  border-radius: 50% !important;
}
.tableData {
  min-width: 500px !important;
}
.MuiCircularProgress-root {
  color: #1c2d62 !important;
}

.homeloading {
  color: #ffffff !important;
}
